import { useAuthInfo, RedirectToLogin } from "@propelauth/react";
import useDemo from 'app/hooks/useDemo';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Navigate } from 'react-router-dom';

import WaitList from 'app/views/sessions/WaitList';

const AuthGuard = ({ children }) => {
  let authInfo = useAuthInfo();
  let { demo } = useDemo();
  if (authInfo.loading) {
    return (
      <Grid container
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Grid item maxWidth="350px">
          <img src="/assets/images/apoxy.svg" alt="Apoxy" width="100%" />
          <LinearProgress sx={{ marginTop: '16px' }} />
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      {authInfo.isLoggedIn || demo ? (
        children
      ) : (
        <RedirectToLogin postLoginRedirectUrl={window.location.href} />
      )}
    </>
  );
};

export default AuthGuard;
