import { AuthProvider } from "@propelauth/react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bfb48f9546caea9d065a3d2dbffb3d6c@o4506619284815872.ingest.sentry.io/4506619568521216",
  enabled: process.env.NODE_ENV === "production",
  integrations: [],
});

const postHogOptions = {
  debug: false,
  autocapture: false,
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={postHogOptions}
  >
    <AuthProvider authUrl={process.env.REACT_APP_PROPELAUTH_DOMAIN}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </StyledEngineProvider>
    </AuthProvider>
  </PostHogProvider>,
  document.getElementById("root"),
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
