import { RedirectToLogin, RedirectToSignup } from "@propelauth/react";
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));
const Logout = Loadable(lazy(() => import('./Logout')));

const sessionRoutes = [
  { path: '/register', element: <RedirectToSignup /> },
  { path: '/signup', element: <RedirectToSignup /> },
  { path: '/login', element: <RedirectToLogin /> },
  { path: '/logout', element: <Logout /> },
  { path: '/404', element: <NotFound /> },
];

export default sessionRoutes;
