import { useTheme } from '@mui/system';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const LineChart = ({ legend, series, labels }) => {
  const theme = useTheme();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
      }
    },
    scales: {
      y: {
        min: 0
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };
  const chartData = {
    labels,
    datasets: series,
  };
  return <Line options={options} data={chartData} />;
};

export default LineChart;
