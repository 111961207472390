import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const Proxies = Loadable(lazy(() => import("./Proxies")));
const Domains = Loadable(lazy(() => import("./Domains")));
const Endpoints = Loadable(lazy(() => import("./Endpoints")));

const exposeRoutes = [
  { path: "/expose/proxies", element: <Proxies /> },
  { path: "/expose/domains", element: <Domains /> },
  { path: "/expose/endpoints", element: <Endpoints /> },
];

export default exposeRoutes;
