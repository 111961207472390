import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CenterLoader } from 'app/components';
import useDemo from 'app/hooks/useDemo';
import axios from 'axios';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
}));

const InitDemoText = styled('h1')(() => ({
  color: '#FFFFFF',
  textAlign: 'center',
  '& a': {
    color: 'rgb(219, 84, 97)',
  },
  '& a:hover': {
    textDecoration: 'underline',
  },
}))

const InitDemoRoot = styled(FlexBox)(() => ({
  background: '#1b1c1e',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
}));

const InitDemo = () => {
  const navigate = useNavigate();
  const { demo, updateDemo } = useDemo();
  const [error, setError] = useState(null);

  useEffect(async () => {
    if (demo) {
      // There's already an active demo? Carry on...
      navigate('/expose/proxies');
      return;
    }
    try {
      let demo = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/v1/terra/demo/start`)
      updateDemo(demo.data.id);
      navigate('/expose/proxies');
    } catch (err) {
      setError(true);
    }
  }, []);

  if (error) {
    return (
      <InitDemoRoot>
        <JustifyBox>
          <InitDemoText>
            Error creating demo environment.<br/><br/>
            Contact: <a href="mailto:support@apoxy.dev">support@apoxy.dev</a>
          </InitDemoText>
        </JustifyBox>
      </InitDemoRoot>
    );
  }

  return (
    <InitDemoRoot>
      <JustifyBox>
        <CenterLoader />
      </JustifyBox>
    </InitDemoRoot>
  );
};

export default InitDemo;
