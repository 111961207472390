import {
  Analytics,
  ArrowsHorizontal,
  Book,
  Choices,
  Folder,
  IbmWatsonDiscovery,
  ModelAlt,
  Notification,
  Plug,
  Purchase,
  ServerDns,
  Vlan,
  Workspace,
} from "@carbon/icons-react";

const iconSize = 24;

export const navigations = [
  { name: "Docs", path: "/docs", icon: <Book size={iconSize} /> },
  { name: "Projects", path: "/projects", icon: <Folder size={iconSize} /> },

  { type: "project" },

  { name: "Overview", path: "/overview", icon: <Workspace size={iconSize} /> },
  {
    name: "Billing",
    path: "/billing",
    icon: <Purchase size={iconSize} />,
    type: "billing",
  },

  { label: "Expose", type: "label" },
  {
    name: "Proxies",
    path: "/expose/proxies",
    icon: <ArrowsHorizontal size={iconSize} />,
  },
  {
    name: "Domains",
    path: "/expose/domains",
    icon: <ServerDns size={iconSize} />,
  },
  {
    name: "Endpoints",
    path: "/expose/endpoints",
    icon: <Vlan size={iconSize} />,
  },

  { label: "Explore", type: "label" },
  {
    name: "Logs",
    path: "/explore/logs",
    icon: <IbmWatsonDiscovery size={iconSize} />,
  },
  {
    name: "Metrics",
    path: "/explore/metrics",
    icon: <Analytics size={iconSize} />,
  },
  //{ name: 'Schemas', path: '/explore/schemas', icon: <ModelAlt size={iconSize} /> },
  //{ name: 'Alerting', path: '/explore/alerting', icon: <Notification size={iconSize} /> },

  { label: "Enhance", type: "label" },
  {
    name: "Extensions",
    path: "/enhance/extensions",
    icon: <Plug size={iconSize} />,
  },
  //{ name: 'Versioning', path: '/enhance/versions', icon: <Choices size={iconSize} /> },

  /*
  {
    name: 'Session/Auth',
    icon: 'security',
    children: [
      { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
      { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
      { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      { name: 'Error', iconText: '404', path: '/session/404' },
    ],
  },

  {
    name: 'Components',
    icon: 'favorite',
    badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
      { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
      { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
      { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
      { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
      { name: 'Form', path: '/material/form', iconText: 'F' },
      { name: 'Icons', path: '/material/icons', iconText: 'I' },
      { name: 'Menu', path: '/material/menu', iconText: 'M' },
      { name: 'Progress', path: '/material/progress', iconText: 'P' },
      { name: 'Radio', path: '/material/radio', iconText: 'R' },
      { name: 'Switch', path: '/material/switch', iconText: 'S' },
      { name: 'Slider', path: '/material/slider', iconText: 'S' },
      { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
      { name: 'Table', path: '/material/table', iconText: 'T' },
    ],
  },

  { label: 'Enhance', type: 'label' },
  {
    name: 'Charts',
    icon: 'trending_up',
    children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  },
  */
];
