import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Extensions = Loadable(lazy(() => import('./Extensions')));

const enhanceRoutes = [
  { path: '/enhance/extensions', element: <Extensions />, auth: authRoles.admin },
];

export default enhanceRoutes;
