import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  LinearProgress,
  Link,
  Icon,
  styled,
  Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Breadcrumb,
  ContentBox
} from 'app/components';
import { green, red } from '@mui/material/colors';
import { useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import useProject from 'app/hooks/useProject';
import axios from 'axios';

const PlanCard = styled(Card)(({ theme }) => ({
  "& span.material-icons": {
    color: theme.palette.secondary.main,
    fontSize: '4rem',
    width: '100%',
    textAlign: 'center',
    marginBottom: '30px',
  },
}));

const PlanActionButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: '30px',
  marginBottom: '30px',
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '30px',
}));

const PlanDetails = styled(Grid)(({ theme }) => ({
  minHeight: '280px',
  textAlign: 'center',
  "& p": {
    lineHeight: '2.5em',
  },
}));

const PlanActions = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

const PricingAlert = styled(Alert)(({ theme }) => ({
  marginBottom: '30px',
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CenterRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
}));

const plans = [
  {
    id: 'free',
    icon: 'person',
    title: 'Free',
    price: '$0',
    features: [
      '1GB log storage',
      '100GB bandwidth',
      'up to 5 requests per second',
      'no TCP/UDP proxying',
    ],
  },
  {
    id: 'std',
    icon: 'storefront',
    title: 'Standard',
    price: '$40/mo',
    features: [
      '100GB log storage',
      '1TB bandwidth',
      '100 requests per second',
      '1 IPv4 address for TCP/UDP',
      <span>&nbsp;</span>,
      '+ $0.25/GB additional log storage',
      '+ $0.05/GB additional bandwidth',
      '+ $20/100 additional requests per second',
      '+ $4 per IPv4 address',
    ],
  },
  {
    id: 'ent',
    icon: 'business',
    title: 'Enterprise',
    price: 'Custom',
    features: [
      'Volume discounts',
      'On-prem deployment options',
      'Support packages available',
      'BYOIP available',
      <span>&nbsp;</span>,
      'Custom usage limits',
      'Custom role based access',
      'GDPR compliance',
      'SSO via SAML',
    ],
  },
];

const Plan = ({ plan, active, authInfo, projectID }) => {
  const [ loading, setLoading ] = useState(false);

  const startStripeSession = async (e) => {
    const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/v1/terra/stripe/subscribe`, {}, {
      headers: {
        'Authorization': `Bearer ${authInfo.accessToken}`,
        'X-Apoxy-Project-Id': projectID
      }
    })
    window.location = res.data.standardCheckoutUrl;
  };

  const upgrade = () => {
    setLoading(true);
    startStripeSession();
  }

  const style = active ? {backgroundColor: '#ddd'} : {};
  let actionButton = <PlanActionButton color="primary" disabled variant="contained" onClick={upgrade} loading={loading}>Change Plan</PlanActionButton>;
  if (active) {
    actionButton = <PlanActionButton disabled variant="outlined">Active Plan</PlanActionButton>;
  } else if (plan.id == 'ent') {
    actionButton = <PlanActionButton color="primary" variant="contained" href="mailto:sales@apoxy.dev">Contact Us</PlanActionButton>;
  }
  return (
    <Grid item sm={4} xs={12}>
      <PlanCard sx={style}>
        <CardHeader title={plan.title} sx={{ textAlign: 'center' }} />
        <CardContent>
          <Icon>{plan.icon}</Icon>
          <PlanPrice variant="h4">{plan.price}</PlanPrice>
          <PlanDetails>
            { plan.features.map((feature, index) =>
              <Typography key={index} variant="body2" color="text.primary">{feature}</Typography>) }
          </PlanDetails>
          <PlanActions>
            {actionButton}
          </PlanActions>
        </CardContent>
      </PlanCard>
    </Grid>
  )
};

const UsageMonitor = ({ kind, label, cap, used }) => {
  const used100 = (used / cap) * 100;
  let usage = `${used} of ${cap} ${label}`;
  if (label == "GB" && used < 1) {
    used = used*1000;
    usage = `${used} MB of ${cap} GB`;
  }
  return (
    <div style={{ margin: '10px 0' }}>
      <Typography variant="subtitle2">{kind} ({usage})</Typography>
      <LinearProgress color="secondary" variant="determinate" value={used100} />
    </div>
  );
};

const InlineButton = styled(Button)(({ theme }) => ({
  padding: 0,
  display: 'inline',
}))

const Billing = (props) => {
  const { redirectToCreateOrgPage } = useRedirectFunctions();
  const authInfo = useAuthInfo();
  let projects = authInfo.orgHelper.getOrgs();
  projects.sort((a, b) => a.orgName > b.orgName ? 1 : -1);
  const { project, updateProject } = useProject();

  const activePlan = 'std';

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Box className="breadcrumb">
          <Breadcrumb
            routeSegments={[
              { name: project.orgName, path: '/projects' },
              { name: 'Billing', path: '/billing' }
            ]}
          />
        </Box>

        <PricingAlert severity="info">
          <AlertTitle>Apoxy is free during beta!</AlertTitle>
          As one of our beta users, we guarantee you'll pay the lowest price possible. Please send us feedback!
        </PricingAlert>

        <PricingAlert severity="warning">
          <AlertTitle>Current usage is NOT accurate.</AlertTitle>
          What to know what your usage actually is? Email us at <InlineButton variant="text" size="small" href="mailto:support@apoxy.dev">support@apoxy.dev</InlineButton>
        </PricingAlert>

        <Card sx={{ margin: '30px 0' }}>
          <CardContent>
            <Typography variant="h6">Current Usage for {project.orgName}</Typography>
            <UsageMonitor
                kind="Log Storage"
                label="GB"
                cap={1}
                used={0} />
            <UsageMonitor
                kind="Bandwidth"
                label="GB"
                cap={100}
                used={0} />
            <UsageMonitor
                kind="Peak Requests Per Second"
                label="rps"
                cap={5}
                used={0} />
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          { plans.map((key, index) => <Plan key={index} plan={key} active={key.id==activePlan} authInfo={authInfo} projectID={project.orgId} />) }
        </Grid>

      </ContentBox>
    </Fragment>
  );
};

export default Billing;
