import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Logs = Loadable(lazy(() => import('./Logs')));
const Metrics = Loadable(lazy(() => import('./Metrics')));
const Monitoring = Loadable(lazy(() => import('./Monitoring')));
const Alerting = Loadable(lazy(() => import('./Alerting')));

const exploreRoutes = [
  { path: '/explore/logs', element: <Logs /> },
  { path: '/explore/metrics', element: <Metrics /> },
  { path: '/explore/monitoring', element: <Monitoring /> },
  { path: '/explore/alerting', element: <Alerting /> },
];

export default exploreRoutes;
