import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "../fake-db";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { ProjectProvider } from "./contexts/ProjectContext";
import { DemoProvider } from "./contexts/DemoContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { JoyrideProvider } from "./contexts/JoyrideContext";
import { TerminalContextProvider } from "react-terminal";
import { Store } from "./redux/Store";
import routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <DemoProvider>
        <ProjectProvider>
          <TerminalContextProvider>
            <SettingsProvider>
              <MatxTheme>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <QueryClientProvider client={queryClient}>
                    {content}
                  </QueryClientProvider>
                </LocalizationProvider>
              </MatxTheme>
            </SettingsProvider>
          </TerminalContextProvider>
        </ProjectProvider>
      </DemoProvider>
    </Provider>
  );
};

export default App;
