import React, { createContext, useEffect, useState } from 'react'
import { useAuthInfo } from "@propelauth/react";
import useDemo from 'app/hooks/useDemo';
import axios from 'axios.js';
import { usePostHog } from 'posthog-js/react';

const ProjectContext = createContext({
    project: {},
    updateProject: () => {},
})

const onProject = (id, projects) => {
  return !!projects.find((e) => e.orgId === id);
}

const defaultProject = (projects) => {
  const id = window.localStorage.getItem('activeProject')
  if (onProject(id, projects)) {
    return projects.find((e) => e.orgId === id)
  }
  return null;
}

export const ProjectProvider = ({ project, children }) => {
  const posthog = usePostHog();
  const authInfo = useAuthInfo();
  const { demo } = useDemo();
  const [currentProject, setCurrentProject] = useState(project || {});
  const [ projects, setProjects ] = useState([]);

  const setProject = (project) => {
    window.localStorage.setItem('activeProject', project.orgId);
    if (project.orgId) {
      axios.defaults.headers.common['X-Apoxy-Project-Id'] = project.orgId;
      posthog?.register({ project: project.orgId });
    } else {
      delete axios.defaults.headers.common['X-Apoxy-Project-Id'];
    }
    return setCurrentProject(project);
  };

  useEffect(() => {
    if (!authInfo.loading && authInfo.accessToken) {
      setProjects(authInfo.orgHelper.getOrgs());
    }
    if (authInfo.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${authInfo.accessToken}`;
      if (authInfo.isImpersonating) {
        posthog?.identify(authInfo.impersonatorUserId);
        posthog?.register({ impersonating: authInfo.user.email });
      } else {
        posthog?.identify(authInfo.user.userId, { email: authInfo.user.email });
        posthog?.unregister('impersonating');
      }
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }, [authInfo.accessToken, posthog]);

  useEffect(() => {
    if (demo && !currentProject.orgName) {
      setProject({ orgName: "Demo" });
      return;
    }
    if (!currentProject.orgId && projects.length > 0) {
      // Select last active project or the first one in the list as a default.
      return setProject(defaultProject(projects) || projects[0]);
    } else if (currentProject.orgId && !onProject(currentProject.orgId, projects)) {
      // Possible account change or no longer on project, default to first project.
      return setProject(projects[0]);
    }
    // All other cases are a valid project selection. Let it be.
  }, [currentProject, projects, demo, posthog])

  return (
    <ProjectContext.Provider
      value={{
        project: currentProject,
        updateProject: setProject,
      }}
    >
    {children}
    </ProjectContext.Provider>
  )
}

export default ProjectContext;
