import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import exploreRoutes from 'app/views/explore/ExploreRoutes';
import exposeRoutes from 'app/views/expose/ExposeRoutes';
import enhanceRoutes from 'app/views/enhance/EnhanceRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import InitDemo from 'app/views/sessions/InitDemo';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import Billing from 'app/views/Billing';
import Projects from 'app/views/Projects';
import AuthDebug from 'app/views/AuthDebug';
import AuthCLI from 'app/views/AuthCLI';
import Docs from 'app/views/Docs';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/projects', element: <Projects /> },
      { path: '/billing', element: <Billing /> },
      { path: '/billing/subscribe', element: <Billing /> },
      { path: '/auth', element: <AuthDebug /> },
      { path: '/auth/cli', element: <AuthCLI /> },
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...exploreRoutes,
      ...exposeRoutes,
      ...enhanceRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="projects" /> },
  { path: '/docs', element: <Docs /> },
  { path: '/demo', element: <InitDemo /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
