import { useTheme } from '@mui/system';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const AreaChart = ({ data, labels, colors }) => {
  const theme = useTheme();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      y: {
        min: 0
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  if (!data || !labels) {
    data = [25, 18, 20, 30, 40, 43, 43, 40, 30, 20, 18, 25];
    labels = ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'];
  }
  if (!colors) {
    colors = [theme.palette.primary.dark, theme.palette.primary.light];
  }
  const chartData = {
    labels,
    datasets: [
      {
        data,
        fill: true,
        borderColor: colors[0],
        backgroundColor: colors[1],
        tension: 0.3,
      },
    ],
  };
  return <Line options={options} data={chartData} />;
};

export default AreaChart;
