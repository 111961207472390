const textLight = {
  primary: "rgba(52, 49, 76, 1)",
  secondary: "rgba(52, 49, 76, 0.54)",
  disabled: "rgba(52, 49, 76, 0.38)",
  hint: "rgba(52, 49, 76, 0.38)",
};

const textDark = {
  primary: "#fff",
  secondary: "rgba(255, 255, 255, 0.7)",
  disabled: "rgba(255, 255, 255, 0.64)",
  hint: "rgba(255, 255, 255, 0.64)",
};

const secondaryColor = {
  light: "#f9a352",
  main: "#ff9e43",
  dark: "#ff932e",
  contrastText: textLight.primary,
};
const errorColor = {
  main: "#FF3D57",
};

export const themeColors = {
  apoxy: {
    palette: {
      type: "light",
      alternate: {
        main: "#ABABAB",
        dark: "#000",
      },
      cardShadow: "rgba(0, 0, 0, .11)",
      common: {
        black: "#1F1C1C",
        white: "#fff",
      },
      mode: "light",
      primary: {
        main: "#AA94EB",
        light: "#F00", // TODO
        dark: "#1F1C1C", // TODO
        contrastText: "#FFFFFF", // TODO
      },
      secondary: {
        main: "#3D91DC",
        light: "#F00", // TODO
        dark: "#F00", // TODO
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        primary: "#1F1C1C",
        secondary: "#1F1C1C",
      },
      divider: "rgba(0, 0, 0, 0.12)",
      background: {
        paper: "#FAFBF1",
        default: "#FAFBF1",
        level2: "#F00", // TODO
        level1: "#F00", // TODO
      },
      link: {
        main: "#BFAB84",
        light: "#F00", // TODO
        dark: "#F00", // TODO
      },
    },
  },
  whitePurple: {
    palette: {
      type: "light",
      primary: {
        main: "#ffffff",
        contrastText: textLight.primary,
      },
      secondary: {
        main: "#7467ef",
        contrastText: "#ffffff",
      },
      background: {
        paper: "#fff",
        default: "#fafafa",
      },
      error: errorColor,
      text: textLight,
    },
  },
  whiteBlue: {
    palette: {
      type: "light",
      primary: {
        main: "#ffffff",
        contrastText: textLight.primary,
      },
      secondary: {
        main: "#1976d2",
        contrastText: "#ffffff",
      },
      background: {
        paper: "#fff",
        default: "#fafafa",
      },
      text: textLight,
    },
  },
  slateDark1: {
    palette: {
      type: "dark",
      primary: {
        main: "#222A45",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
      text: textDark,
    },
  },
  slateDark2: {
    palette: {
      type: "dark",
      primary: {
        main: "#1a2038",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
      text: textDark,
    },
  },
  purple1: {
    palette: {
      type: "light",
      primary: {
        main: "#7467ef",
        contrastText: "#ffffff",
      },
      secondary: secondaryColor,
      error: errorColor,
      background: {
        paper: "#fff",
        default: "#fafafa",
      },
      text: textLight,
    },
  },
  purple2: {
    palette: {
      type: "light",
      primary: {
        main: "#6a75c9",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#fff",
        default: "#fafafa",
      },
      text: textLight,
    },
  },
  purpleDark1: {
    palette: {
      type: "dark",
      primary: {
        main: "#7467ef",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
      text: textDark,
    },
  },
  purpleDark2: {
    palette: {
      type: "dark",
      primary: {
        main: "#6a75c9",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
      text: textDark,
    },
  },
  // Main Theme
  blue: {
    palette: {
      type: "light",
      primary: {
        main: "#3D91DC",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#E86C4B",
        contrastText: "#1F1C1C",
      },
      error: errorColor,
      background: {
        paper: "#FAFBF1",
        default: "#FAFBF1",
      },
      text: textLight,
      divider: "rgba(0, 0, 0, 0.12)",
    },
  },
  // Sidebar
  blueDark: {
    palette: {
      type: "dark",
      primary: {
        main: "#1976d2",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#FF4F30",
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
      text: textDark,
      divider: "rgba(0, 0, 0, 0.12)",
    },
  },
  red: {
    palette: {
      type: "dark",
      primary: {
        main: "#e53935",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#FFAF38",
        contrastText: textLight.primary,
      },
      error: errorColor,
      text: textDark,
    },
  },
};

export const themeShadows = [
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
];
