import { Box, CircularProgress, styled } from '@mui/material';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const CenteredRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CenterLoader = () => {
  return (
    <CenteredRoot>
      <JustifyBox>
        <CircularProgress />
      </JustifyBox>
    </CenteredRoot>
  );
};

export default CenterLoader;
