import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  styled,
  Typography
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import {
  Breadcrumb,
  ContentBox
} from 'app/components';
import { green, red } from '@mui/material/colors';
import { useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import useProject from 'app/hooks/useProject';
import useDemo from 'app/hooks/useDemo';
import axios from 'axios.js';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: '.5em auto',
  width: '100%',
}));

const ContainProgress = styled(Box)(() => ({
  textAlign: 'center',
}));

const Breadcrumbs = ({ project }) => {
  let segments = [
    { name: 'CLI Login', path: '/auth/cli' },
  ];
  if (project && project.orgName) {
    segments = [
      { name: 'CLI Login', path: '/auth/cli' },
      { name: project.orgName, path: '/projects' },
    ];
  }
  return (
    <Box className="breadcrumb">
      <Breadcrumb routeSegments={segments} />
    </Box>
  );
}

const AuthCLI = (props) => {
  const { redirectToCreateOrgPage } = useRedirectFunctions();
  const authInfo = useAuthInfo();
  let projects = [];
  if (authInfo.accessToken) {
    projects = authInfo.orgHelper.getOrgs();
    projects.sort((a, b) => a.orgName > b.orgName ? 1 : -1);
  }

  let location = useLocation();
  const [selectedProject, setSelectedProject] = useState({});

  if (selectedProject && selectedProject.orgName) {
    const createKeyAndAuthenticate = async () => {
      let keysReq = await axios.post(`${process.env.REACT_APP_API_DOMAIN}/v1/terra/api-keys`, {}, {
        headers: {
          'X-Apoxy-Project-Id': selectedProject.orgId,
        }
      });
      window.location = decodeURIComponent(location.search.substr(1).split('=')[1]) + '?key=' + keysReq.data.keys[0] + '&project=' + selectedProject.orgId;
    }
    createKeyAndAuthenticate();
    return (
      <ContentBox className="analytics">
        <Breadcrumbs project={selectedProject} />
        <Card sx={ {width: '400px', margin: '0 auto'} }>
          <CardContent>
            <Typography variant="h5" color="text.primary" align="center">CLI Login</Typography>

            <Typography variant="body2" color="text.secondary" align="center" sx={{ margin: '30px 0' }}>
              Authenticating...
            </Typography>
            <ContainProgress>
              <CircularProgress />
            </ContainProgress>
          </CardContent>
        </Card>
      </ContentBox>
    );
  }

  return (
    <ContentBox className="analytics">
      <Breadcrumbs project={selectedProject} />
      <Card sx={ {width: '400px', margin: '0 auto'} }>
        <CardContent>
          <Typography variant="h5" color="text.primary" align="center">CLI Login</Typography>

          <Typography variant="body2" color="text.secondary" align="center" sx={{ margin: '30px 0' }}>
            Select the project you would like to use:
          </Typography>
          { projects.map((project) => (
            <StyledButton
              key={project.orgId} color="primary" variant="outlined"
              onClick={() => setSelectedProject(project)}
            >
              {project.orgName}
            </StyledButton>
          ))}
        </CardContent>
      </Card>
    </ContentBox>
  );
};

export default AuthCLI;
