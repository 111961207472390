import {
  Avatar,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import {
  Breadcrumb,
  ContentBox
} from 'app/components';
import { green, red } from '@mui/material/colors';
import { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuthInfo, useRedirectFunctions, RedirectToSignup } from "@propelauth/react";
import useProject from 'app/hooks/useProject';
import useDemo from 'app/hooks/useDemo';

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: '30px',
}));

const ProjectActionButton = styled(Button)(({ theme }) => ({
  margin: '10px',
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  let parts = name.split(' ');
  let children = name.length > 2 && name[1] != ' ' ?
    (parts.length > 1 && parts[1] != '' ? `${parts[0][0]}${parts[1][0]}` : `${name[0]}${name[1]}`) :
    name[0];
  return {
    sx: {
      bgcolor: stringToColor(name),
			cursor: 'pointer',
    },
    children: children,
  };
}

const ProjectActions = ({ project, setProject }) => {
  const { redirectToOrgPage } = useRedirectFunctions();
  const manageOrg = () => redirectToOrgPage(project.orgId);
  const updateProject = () => setProject(project);
  const authInfo = useAuthInfo();
  const billingAction = authInfo.accessHelper.hasPermission(project.orgId, 'apoxy::billing_admin') ?
    <Link to="/billing" onClick={updateProject}><ProjectActionButton color="primary" variant="outlined">Billing</ProjectActionButton></Link>
    : <Fragment />;
  return (
    <Grid sx={{ padding: '7px', display: 'flex', justifyContent: 'flex-end' }}>
      {billingAction || <Fragment/>}
      <ProjectActionButton color="primary" variant="outlined" onClick={manageOrg}>Manage</ProjectActionButton>
      <Link to="/overview" onClick={updateProject}><ProjectActionButton color="primary" variant="outlined">Overview</ProjectActionButton></Link>
    </Grid>
  );
};

const ProjectCard = ({ project, setProject }) => {
  const updateProject = () => setProject(project);
  const avatar = (<Link to="/overview" onClick={updateProject}><Avatar {...stringAvatar(project.orgName)} /></Link>);
  const subheader = project.userAssignedRole;
  return (
    <Card sx={{ width: '100%' }}>
      <Grid container>
        <Grid item md={6} sm={12}>
          <CardHeader
            avatar={avatar}
            title={<MaterialLink underline="hover" href="/overview" onClick={updateProject}>{project.orgName}</MaterialLink>}
            subheader={subheader}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <ProjectActions item md={6} sm={12} project={project} setProject={setProject} />
        </Grid>
      </Grid>
    </Card>
  );
};

const Projects = (props) => {
  const { redirectToCreateOrgPage } = useRedirectFunctions();
  const authInfo = useAuthInfo();
  let projects = [];
  if (authInfo.accessToken) {
    projects = authInfo.orgHelper.getOrgs();
    projects.sort((a, b) => a.orgName > b.orgName ? 1 : -1);
  }
  const { project, updateProject } = useProject();
  const { demo } = useDemo();
  if (demo) {
    return <RedirectToSignup />;
  }
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Box className="breadcrumb">
          <Breadcrumb
            routeSegments={[{ name: 'Projects', path: '/projects' }]}
          />
        </Box>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <StyledButton color="primary" variant="contained" onClick={redirectToCreateOrgPage}>Create Project</StyledButton>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
          { projects.map((key, index) => (<ProjectCard key={index} project={key} setProject={updateProject} />)) }
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Projects;
