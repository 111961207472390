import React, { createContext, useEffect, useState } from 'react'
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios.js';
import { isEmpty } from 'lodash';
import { usePostHog } from 'posthog-js/react';

const DemoContext = createContext({
    demo: null,
    updateDemo: () => {},
})

export const DemoProvider = ({ demo, children }) => {
  const posthog = usePostHog();
  const authInfo = useAuthInfo();
  const [currentDemo, setCurrentDemo] = useState(demo);

  const setDemo = (demo) => {
    if (demo && !isEmpty(demo)) {
      window.localStorage.setItem('activeDemo', demo);
      axios.defaults.headers.common['X-Apoxy-Demo-Id'] = demo;
      posthog?.register({ demo: demo });
      return setCurrentDemo(demo);
    } else {
      window.localStorage.removeItem('activeDemo');
      delete axios.defaults.headers.common['X-Apoxy-Demo-Id'];
      posthog?.unregister('demo');
      return setCurrentDemo(demo);
    }
  };

  useEffect(() => {
    if (!currentDemo) {
      // Select last active demo or the first one in the list as a default.
      let lastDemo = window.localStorage.getItem('activeDemo');
      if (lastDemo) {
        return setDemo(lastDemo);
      }
    } else {
      // Now logged in and we had an active demo.
      if (!authInfo.loading && authInfo.accessToken) {
        console.log(`TODO: adopt demo env ${currentDemo} for current user`);
        posthog?.capture('demo conversion', { demo: currentDemo, user: authInfo.user.userId });
        setDemo(null);
      }
    }
  }, [currentDemo, authInfo.accessToken, posthog])

  return (
    <DemoContext.Provider
      value={{
        demo: currentDemo,
        updateDemo: setDemo,
      }}
    >
    {children}
    </DemoContext.Provider>
  )
}

export default DemoContext;
