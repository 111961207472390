import { Breadcrumbs, Icon, styled, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  CaretRight,
  Home,
} from '@carbon/icons-react';

const BreadcrumbRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  'a:hover span': {
    color: theme.palette.primary.main,
  },
  '.MuiBreadcrumbs-separator': {
    position: 'relative',
    top: '1px',
  }
}));

const SubName = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledHomeIcon = styled(Home)(({ theme }) => ({
  verticalAlign: 'middle',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Breadcrumb = ({ routeSegments }) => {
  return (
    <BreadcrumbRoot>
      <Breadcrumbs
        separator={<CaretRight size="20" />}
        sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <NavLink to="/">
          <StyledHomeIcon size="24" />
        </NavLink>

        {routeSegments
          ? routeSegments.map((route, index) => {
              return index !== routeSegments.length - 1 ? (
                <NavLink key={index} to={route.path}>
                  <SubName>{route.name}</SubName>
                </NavLink>
              ) : (
                <SubName key={index}>{route.name}</SubName>
              );
            })
          : null}
      </Breadcrumbs>
    </BreadcrumbRoot>
  );
};

export default Breadcrumb;
