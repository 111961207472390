import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuthInfo } from "@propelauth/react";
import useProject from 'app/hooks/useProject';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
  width: '100%',
  marginBottom: '32px',
}));

const ProvisioningText = styled('h1')(() => ({
  color: '#1F1C1C',
  textAlign: 'center',
}))

const ProvisioningRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '160px 0',
}));

const Provisioning = () => {
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const { project } = useProject();

  if (true) {
    return null;
  }

  return (
    <ProvisioningRoot>
      <JustifyBox>

        <ProvisioningText>We're provisioning this project.<br/>You'll receive an email when it is ready!</ProvisioningText>

        <a href="mailto:support@apoxy.dev">
          <Button
            color="primary"
            variant="outlined"
            sx={{
              fontSize: '1.1rem',
              marginTop: '2rem',
              borderRadius: 0,
              border: '1px solid rgba(255, 255, 255, 0)',
              '&:hover': {
                border: '1px solid #3D91DC',
              },
            }}
          >
            Please email support if it has been too long
          </Button>
        </a>
      </JustifyBox>
    </ProvisioningRoot>
  );
};

export default Provisioning;
