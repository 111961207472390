import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
  width: '100%',
  marginBottom: '32px',
}));

const WaitListText = styled('h1')(() => ({
  color: '#ABABAB',
  textAlign: 'center',
}))

const WaitListRoot = styled(FlexBox)(() => ({
  background: '#1F1C1C',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
}));

const WaitList = () => {
  const navigate = useNavigate();

  return (
    <WaitListRoot>
      <JustifyBox>
        <a href="https://apoxy.dev">
          <IMG src="/assets/images/apoxy-grey.svg" alt="" />
        </a>

        <WaitListText>Awesome.<br/>You're on the list!</WaitListText>

        <a href="mailto:hello@apoxy.dev">
          <Button
            color="primary"
            variant="outlined"
            sx={{
              fontSize: '1.1rem',
              marginTop: '2rem',
              borderRadius: 0,
              border: '1px solid rgba(255, 255, 255, 0)',
              '&:hover': {
                border: '1px solid #3D91DC',
              },
            }}
          >
            Tell us why you should cut the line
          </Button>
        </a>
      </JustifyBox>
    </WaitListRoot>
  );
};

export default WaitList;
