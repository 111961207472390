import {
  Avatar,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import {
  Breadcrumb,
  ContentBox
} from 'app/components';
import { green, red } from '@mui/material/colors';
import { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import useProject from 'app/hooks/useProject';
import useDemo from 'app/hooks/useDemo';

const ProjectDebug  = ({ project, active }) => {
  return (
    <div>
      {active ? <strong>ACTIVE</strong> : <Fragment />}
      <p>{project.orgName}</p>
      <p><code>{project.orgId}</code></p>
      <hr/>
    </div>
  )
}

const AuthDebug = (props) => {
  const { redirectToCreateOrgPage } = useRedirectFunctions();
  const authInfo = useAuthInfo();
  let projects = [];
  if (authInfo.accessToken) {
    projects = authInfo.orgHelper.getOrgs();
    projects.sort((a, b) => a.orgName > b.orgName ? 1 : -1);
  }
  const { project } = useProject();
  const { demo } = useDemo();
  if (demo) {
    return (
      <Fragment>
        <ContentBox>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}><code>DEMO {demo}</code></p>
            </div>
          </Grid>
        </ContentBox>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ContentBox>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div>
            <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}><code>{authInfo.accessToken}</code></p>
            <hr/>
          </div>
          { projects.map((p, index) => (<ProjectDebug project={p} active={project.orgId == p.orgId} />)) }
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default AuthDebug;
